<template>
  <div class="tags">
    <b-container>
      <OnboardingHeader
        :title="title"
      ></OnboardingHeader>
      <TagsBox
        :tagCategory="tagsRef"
        :title="title"
        :LikeFields="LikeFields"
        :DislikeFields="DislikeFields"
        :likesText="likesText"
        :dislikesText="dislikesText"
        :oid="oid"
      ></TagsBox>
    </b-container>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/sections/OnboardingHeader.vue";

import TagsBox from "@/components/interfaces/TagsBox.vue";
export default {
  components: { OnboardingHeader, TagsBox },
  data() {
    return {
      currentCat: 0,
      oid: 4,
      step: "5",
      title: "Fields of Interest",
      time: "Less than 3 Minutes",
      taxonomy: "industries",
      tagsRef: "industries",
      LikeFields: ["Fields I Enjoy", "Category"],
      DislikeFields: ["Fields I Don't Care For", "Category"],
      likesText:
        'This will be a list of fields that interest you. For example, if you are fascinated with "Space Exploration", we would widen our search to include that.',
      dislikesText:
        "This will be a list of fields that don't interest you in the slightest. For example, if you don't get the big deal with \"Display Advertising\", we would limit our search to explicitly remove companies / roles who focus on that.",
      currentUser: this.$store.getters.getUID,
      headerBox:{
        directions: "Please thumbs up or thumbs down as many of these as you'd like. It's OK to leave a field alone and be neutral.",
        what:"We all have numerous interests, many of them in the professional world. We also may have anti-interests, or things that you are definitely <b>not</b> interested in.",
        benefits:"If something is in your anti-interest list, we will try not to present you with any opportunities related to that field.",
        reasons:"No one knows what is inside your head. Knowing more about your actual interests helps us get closer to finding you a job that you love."

      }
    };
  },
};
</script>
<style scoped></style>
